import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../../pages/content-pages/login/auth.service';
import {Observable} from 'rxjs';
import {UserType} from '../enums/user-type.enum';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.userType || UserType.Patient;
      if (route.data.role && route.data.role.indexOf(userRole) === -1) {
        this.router.navigate(['/e-services']);
        return false;
      }
      return true;
    }

    this.router.navigate(['/e-services']);
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.userType || UserType.Patient;
      if ((userRole !== route.data.role) && (route.data.role.indexOf(userRole) === -1)) {
        let role = '';
        if (userRole === 1) { role = 'admin'; }
        if (userRole === 2) { role = 'gift-store-management'; }
        if (userRole === 4) { role = 'restaurant-management'; }
        if (userRole === 5) { role = 'gallery-management'; }

        this.router.navigate([`/admin/dashboard`]);
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ReverseAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.userType || UserType.Patient;
      if (userRole === UserType.Admin || userRole === UserType.GiftStoreManagement || userRole === UserType.RestaurantManagement || userRole === UserType.GalleryManagement) {
        this.router.navigate(['/admin/dashboard']);
        return false;
      }
    }
    return true;
  }
}
