import {Injectable} from '@angular/core';
import {HttpClient , HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SnotifyService} from 'ng-snotify';
import {StorageService} from '../../../storage.service';
import {decode, encode, remove} from '../../../utils';
import {UserType} from '../../../core/enums/user-type.enum';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  next: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  regUser: any;
  user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  lastPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  resetPassword: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  verifyPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  setNewPasswordPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly httpClient: HttpClient,
    private readonly snotifyService: SnotifyService,
    private readonly storageService: StorageService,
    private readonly router: Router
    ) {
    this.currentUser = new BehaviorSubject<any>(
      decode('currentUser')
    );
  }

  public get currentUserValue() {
    return this.currentUser.value;
  }

  signup(body, val?) {
    this.regUser = body;
    this.httpClient.post(`${environment.paymentApiUrl}/auth/register`, body).subscribe(
      (data) => {
        this.next.next(true);
      },
      (error) => {
        if (error.error.statusCode === 409) {
          this.snotifyService.error(error.error.message, '', {
            position: 'rightTop'
          });
        }
        if (error.error.statusCode === 403) {
          this.verifyPage.next(true);
          this.snotifyService.error(error.error.message, '', {
            position: 'rightTop'
          });
          if (!val) {
            this.next.next(true);
          }
        } if (error.error.statusCode === 404) {
          this.snotifyService.error(error.error.message, '', {
            position: 'rightTop'
          });
        } else {
          this.snotifyService.error('Incorrect Credentials', '', {
            position: 'rightTop'
          });
        }
      }
    )
  }

  setNextBtn(val) {
    this.next.next(val);
  }

  resend(val) {
    this.signup(this.regUser, val);
  }

  verification(body) {
    body = {
      ...body,
      udhId: this.regUser.udhId
    };
    this.regUser = {
      ...this.regUser,
      passcode: body.passcode
    }
    this.httpClient.post(`${environment.paymentApiUrl}/auth/verify-passcode`, body).subscribe(
      (data: any) => {
        if (data) {
          this.snotifyService.success(data.message, '', {
            position: 'rightTop'
          });
          this.user.next(data.data);
          this.next.next(true);
          this.verifyPage.next(false);
          this.setNewPasswordPage.next(true)
        }
      },
      (error) => {
        this.snotifyService.error(error.error.message, '', {
          position: 'rightTop'
        });
      }
    )
  }

  setPassword(body) {
    body = {
      ...body,
      udhId: this.regUser.udhId,
      passcode: this.regUser.passcode
    };

    this.httpClient.patch(`${environment.paymentApiUrl}/auth/set-password`, body).subscribe(
      (data: any) => {
        this.snotifyService.success(data.message, '', {
          position: 'rightTop'
        });
        this.next.next(true);
        this.setNewPasswordPage.next(false);
        this.lastPage.next(true);
      },
      (error) => {
        this.snotifyService.error(error.error.message, '', {
          position: 'rightTop'
        });
      }
    )
  }

  login(body) {
    this.httpClient.post(`${environment.paymentApiUrl}/auth/login`, body , {
      headers: new HttpHeaders({
        'Authorization': `ak ${environment.key}`,
        'Content-Type': 'application/json'
      })
    }).subscribe(
      (data: any) => {
        if (data.user) {
          encode('currentUser', data.user);
          if (data.access_token) {
            encode('token', data.access_token);
          }
          this.currentUser.next(data.user);
          this.checkUserRole(data.user)
        }
        this.snotifyService.success('You are logged in', '', {
          position: 'rightTop'
        });
      },
      (error) => {
        this.snotifyService.error(error.error.message, '', {
          position: 'rightTop'
        });
      }
    )
  }

  logout() {
    remove('currentUser');
    remove('token');
    this.currentUser.next(null);
    this.router.navigate(['/']);
  }

  forgotPassword(body: any) {
    this.regUser = body;
    this.httpClient.post(`${environment.paymentApiUrl}/auth/forgot-password`, body).subscribe(
      (data: any) => {
        this.snotifyService.success(data.message, '', {
          position: 'rightTop'
        });

        this.resetPassword.next(false);
        this.verifyPage.next(true);
      },
      (error) => {
        this.snotifyService.error(error.error.message, '', {
          position: 'rightTop'
        });
      })
  }

  private checkUserRole(user) {
    if (user) {
      const role = user.userType || UserType.Patient;
      if (role === UserType.Admin) {
        this.router.navigate(['/admin/dashboard']);
      } else if (role === UserType.GiftStoreManagement) {
        this.router.navigate(['/admin/gift-store-management/orders']);
      } else if (role === UserType.RestaurantManagement) {
        this.router.navigate(['/admin/restaurant-management/orders']);
      } else if (role === UserType.GalleryManagement) {
        this.router.navigate(['/admin/gallery-management/all']);
      } else if (role === UserType.Patient) {
        this.currentUser.next(user);
      }
    }
  }
}
