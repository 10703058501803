import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'app-top-rated',
  templateUrl: './top-rated.component.html',
  styleUrls: ['./top-rated.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class TopRatedComponent implements OnInit {
  @Input() item: any;
  constructor() {
  }

  ngOnInit() {
  }
}
