import {Component, Input, OnInit} from "@angular/core";
import {ORDER_TYPE} from "../../core/enums/order-type.enum";
import {IOrdersCount} from "../../core/interfaces/IDashboard";

@Component({
  selector: 'app-services-orders',
  templateUrl: './services-orders.component.html',
  styleUrls: ['./services-orders.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class ServicesOrdersComponent implements OnInit {
  @Input() serviceOrders: IOrdersCount;

  ORDER_TYPE = ORDER_TYPE;

  constructor() {
  }
  ngOnInit() {
  }
}
