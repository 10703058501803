export enum ServiceTypeEnum {
  HOUSEKEEPING = 1,
  PATIENT_RELATION = 2,
	REQUEST_DOCTOR = 3,
	MAINTINANCE = 4,
	DUTY_MANAGER = 5,
  BABY_REQUSET=6,
	BABY_PULL=7,
  MEDICAL_REPORT = 8,
  COMPANION_REPORT = 9,
}
