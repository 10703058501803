import { RouteInfo } from './sidebar.metadata';
import {AuthService} from '../../pages/content-pages/login/auth.service';
import { routes } from '../routes';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/e-services',
    title: 'الخدمات الإلكترونية',
    icon: 'icon-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    role: [0, 3],
    type: ''
  },
    /*{
      path: '/e-services/UDHLive',
      title: 'onlineSessions.title',
      icon: 'icon-camcorder',
      class: '',
      badge: 'جديد',
      badgeClass: 'badge badge-pill badge-danger mt-1',
      isExternalLink: false,
      submenu: [],
      type: ''
    },*/

  // {
  //   path: '',
  //   title: 'المواعيد',
  //   icon: 'icon-clock',
  //   class: 'has-sub',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   type: '',
  //   submenu: [],
  // },
  {
    path: '/e-services/reservations',
    title: 'حجز جديد',
    icon: 'icon-plus',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [0, 3]
  },
  {
    path: '/e-services/appointments',
    title: 'إدارة حجوزاتي',
    icon: 'icon-calendar',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [0, 3]
  },

  {
    path: '/e-services/invoice',
    title: 'invoices.',
    icon: 'icon-credit-card',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [0, 3]
  },

  // {
  //   path: '',
  //   title: 'التقارير الطبية',
  //   icon: 'icon-docs',
  //   class: 'has-sub',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   type: '',
  //   submenu: [
  //   ],
  // },

  {
    path: '/e-services/labs',
    title: 'نتائج المختبر',
    icon: 'icon-docs',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [0, 3]
  },
  {
    path: '/e-services/radiology',
    title: 'تقارير الأشعة',
    icon: 'icon-doc',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: '',
    role: [0, 3]
  },
  {
    path: '/e-services/user-management',
    title: 'sidenav.userManagement',
    icon: 'icon-users',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [3],
    type: '',
    submenu: [
      {
        path: '/e-services/user-management/change-password',
        title: 'sidenav.changePassword',
        icon: 'icon-lock-open',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [3],
        type: '',
        submenu: []
      },
      {
        path: '/e-services/user-management/edit-information',
        title: 'sidenav.editInformation',
        icon: 'icon-note',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [3],
        type: '',
        submenu: []
      },
    ],
  },

  // Admin Modules
  {
    path: routes.ADMIN.DASHBOARD,
    title: 'sidenav.dashboard',
    icon: 'icon-home',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [1],
    type: '',
    submenu: [],
  },
  {
    path: routes.ADMIN.USER_MANAGEMENT,
    title: 'sidenav.userManagement',
    icon: 'icon-users',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [1, 2, 4, 5],
    type: '',
    submenu: [
      {
        path: routes.ADMIN.USER_MANAGEMENT_ADD,
        title: 'sidenav.addNewUser',
        icon: 'icon-user-follow',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1],
        type: '',
        submenu: []
      },
      {
        path: routes.ADMIN.USER_MANAGEMENT_ALL,
        title: 'sidenav.usersList',
        icon: 'icon-user-follow',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1],
        type: '',
        submenu: []
      },
      {
        path: routes.ADMIN.CHANGE_PASSWORD,
        title: 'sidenav.changePassword',
        icon: 'icon-lock-open',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 2, 4, 5],
        type: '',
        submenu: []
      },
      {
        path: routes.ADMIN.EDIT_INFORMATION,
        title: 'sidenav.editInformation',
        icon: 'icon-note',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 2, 4, 5],
        type: '',
        submenu: []
      },
    ],
  },
  {
    path: routes.ADMIN.GALLERY_MANAGEMENT,
    title: 'sidenav.galleryManagement',
    icon: 'icon-layers',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [1, 5],
    type: '',
    submenu: [
      {
        path: routes.ADMIN.GALLERY_MANAGEMENT_ADD,
        title: 'sidenav.addPicture',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 5],
        type: '',
        submenu: []
      },
      {
        path: routes.ADMIN.GALLERY_MANAGEMENT_ALL,
        title: 'sidenav.pictureList',
        icon: 'icon-picture',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 5],
        type: '',
        submenu: []
      },
    ],
  },

  {
    path: routes.ADMIN.GIFT_STORE_MANAGEMENT,
    title: 'sidenav.giftStoreManagement',
    icon: 'icon-present',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [1, 2],
    type: '',
    submenu: [
      {
        path: routes.ADMIN.GIFT_ORDERS,
        title: 'sidenav.orders',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 2],
        type: '',
        submenu: []
      },
      {
        path: routes.ADMIN.GIFT_PRODUCTS_SETUP,
        title: 'sidenav.productsSetup',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 2],
        type: '',
        submenu: []
      },
      {
        path: routes.ADMIN.GIFT_TAGS_SETUP,
        title: 'sidenav.tags',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 2],
        type: '',
        submenu: []
      },
      {
        path: routes.ADMIN.GIFT_TAGS_LIST,
          title: 'sidenav.tags_list',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 2],
        type: '',
        submenu: []
      },
    ],
  },
  {
    path: routes.ADMIN.RESTAURANT_MANAGEMENT,
    title: 'sidenav.restaurantManagement',
    icon: 'icon-cup',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    role: [1, 4],
    type: '',
    submenu: [
      {
        path: routes.ADMIN.RESTAURANT_ORDERS,
        title: 'sidenav.orders',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 4],
        type: '',
        submenu: []
      },
      {
        path: routes.ADMIN.RESTAURANT_PRODUCTS_SETUP,
        title: 'sidenav.productsSetup',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 4],
        type: '',
        submenu: []
      },
      {
        path: routes.ADMIN.RESTAURANT_TAGS_SETUP,
        title: 'sidenav.tags',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 4],
        type: '',
        submenu: []
      },
      {
        path: routes.ADMIN.RESTAURANT_TAGS_LIST,
        title: 'sidenav.tags_list',
        icon: 'icon-puzzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        role: [1, 4],
        type: '',
        submenu: []
      },
    ],
  },


  {
    path: '',
    title: 'auth.logout',
    icon: 'icon-logout',
    action: {
      type: 'logout',
      action: (auth: AuthService) => {
        auth.logout();
      }
    },
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    type: 'logout',
    role: [1, 2, 3, 4, 5]
  }
];
