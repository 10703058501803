import {AfterViewInit, Component, Input, OnInit, ViewChild} from "@angular/core";
import {DatatableComponent, ColumnMode} from "@swimlane/ngx-datatable";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GiftStoreService} from "../../core/services/gift-store.service";
import {ProductsModalComponent} from "../products-modal/products-modal.component";
import {ProductsSetupService} from "../../core/services/products-setup.service";

@Component({
  selector: 'app-products-table',
  templateUrl: './products-table.component.html',
  styleUrls: ['./products-table.component.scss'],
})
export class ProductsTableComponent implements OnInit {
  @Input() items: any;
  @Input() type: any;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  temp = [];

  ColumnMode = ColumnMode;

  constructor(private modalService: NgbModal,
              private productsSetupService: ProductsSetupService,
              private giftStoreService: GiftStoreService) {
  }
  ngOnInit() {
    this.giftStoreService.getAllTags(this.type);
    setTimeout(() => {
      this.temp = this.items;
    }, 2000)
  }

  openProductsModal(event, id, firstChild: any) {
    event.stopPropagation();

    firstChild && firstChild.parentElement && firstChild.parentElement.parentElement &&
    firstChild.parentElement.parentElement.blur();
    let modalRef = this.modalService.open(ProductsModalComponent, {centered: true, size: 'lg'});

    modalRef.componentInstance.type = this.type;

    this.productsSetupService.selectProductsItem(id, this.type)
  }

  updateFilter($event) {
    const val = $event.target.value.toLowerCase();

    const temp = [];
    temp.push(...this.temp.filter(function (d) {
      return (d.serviceCode && d.serviceCode.toLowerCase().indexOf(val) !== -1) ||
        (d.serviceNameEn && d.serviceNameEn.toLowerCase().indexOf(val) !== -1) ||
        (d.serviceNameAr && d.serviceNameAr.toLowerCase().indexOf(val) !== -1) ||
        (d.isActiveText && d.isActiveText.toLowerCase().indexOf(val) !== -1) ||
        (d.onlineActiveText && d.onlineActiveText.toLowerCase().indexOf(val) !== -1) || !val;
    }));
    this.items = temp;
    this.table.offset = 0;
  }
}
