import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {AngularFireStorage} from '@angular/fire/storage';

import {BehaviorSubject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {FileUpload} from '../models/file-upload.model';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private basePath;
  fileUrl: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage) { }

  setBasePath(path) {
    this.basePath = path;
  }

  pushFileToStorage(fileUpload: FileUpload): void {
    const filePath = `${this.basePath}/${this.getRandomFileName(fileUpload.file.name)}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          fileUpload.url = downloadURL;
          fileUpload.name = fileUpload.file.name;
          this.saveFileData(fileUpload);
          this.saveDownloadUrl(downloadURL);
        });
      })
    ).subscribe(val => {
      console.log(val, 111111111111111111111111111)
    });
  }

  getRandomFileName(name) {
    let timestamp = new Date().toISOString().replace(/[-:.]/g,"");
    return timestamp + '_' + name;
  }

  private saveFileData(fileUpload: FileUpload): void {
    this.db.list(this.basePath).push(fileUpload);
  }

  getFiles(numberItems): AngularFireList<FileUpload> {
    return this.db.list(this.basePath, ref =>
      ref.limitToLast(numberItems));
  }

  deleteFile(fileUpload: FileUpload): void {
    this.deleteFileDatabase(fileUpload.key)
      .then(() => {
        this.deleteFileStorage(fileUpload.name);
      })
      .catch(error => console.log(error));
  }

  private deleteFileDatabase(key: string): Promise<void> {
    return this.db.list(this.basePath).remove(key);
  }

  private deleteFileStorage(name: string): void {
    const storageRef = this.storage.ref(this.basePath);
    storageRef.child(name).delete();
  }

  private saveDownloadUrl(downloadURL: any) {
    this.fileUrl.next(downloadURL)
  }
}
