import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {DatatableComponent, ColumnMode} from "@swimlane/ngx-datatable";
import {ORDER_TYPE} from "../../core/enums/order-type.enum";
import {ServiceTypeEnum} from "../../core/enums/service-type.enum";

@Component({
  selector: 'app-in-patient',
  templateUrl: './inPatient.component.html',
  styleUrls: ['./inPatient.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class InPatientComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  @Input() rows: any[];
  @Input() totalRequest: number;
  @Input() overallSatisfaction: number;
  ServiceType = ServiceTypeEnum;
  lang: string;

  constructor() {
    this.lang = localStorage.getItem('lang');
  }

  ngOnInit() {
  }

}
