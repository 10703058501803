import * as SecureLS from 'secure-ls';
let ls = new SecureLS({encodingType: 'aes'});

export const encode = (key: string, data: any) => {
  ls.set(key, data);
}

export const decode = (key: string) => {
  return ls.get(key)
}

export const remove = (key: string) => {
  ls.remove(key);
}

export const getAllKeys = () => {
  return ls.getAllKeys()
}
