import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {decode} from '../../utils';
import {SnotifyService} from 'ng-snotify';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from "rxjs";
import {FileUploadService} from "./file-upload.service";
import {IGiftStore} from "../interfaces/IGiftStore";
import {ORDER_TYPE} from "../enums/order-type.enum";
import {ITags} from "../interfaces/ITags";

@Injectable({
  providedIn: 'root'
})
export class GiftStoreService {
  httpOptions = {
    'method': 'GET',
    headers: new HttpHeaders({
      'Authorization': `ak ${environment.key}`,
      'jwt-token': decode('token')
    })
  };

  giftStores: BehaviorSubject<IGiftStore[]> = new BehaviorSubject<IGiftStore[]>(null);
  restaurants: BehaviorSubject<IGiftStore[]> = new BehaviorSubject<IGiftStore[]>(null);
  giftTags: BehaviorSubject<ITags[]> = new BehaviorSubject<ITags[]>(null);
  restaurantTags: BehaviorSubject<ITags[]> = new BehaviorSubject<ITags[]>(null);
  type: ORDER_TYPE;
  item: BehaviorSubject<IGiftStore> = new BehaviorSubject<IGiftStore>(null);

  constructor(private readonly httpClient: HttpClient,
              private readonly snotifyService: SnotifyService,
              private readonly translate: TranslateService,
              private readonly fileUploadService: FileUploadService) {
  }

  getGiftStores(): IGiftStore[] {
    return this.giftStores.value;
  }

  getRestaurants(): IGiftStore[] {
    return this.restaurants.value;
  }

  /**
   * @description Get all services orders
   * */
  getAllServicesOrders(type) {
    this.type = type;
    this.httpClient.get(`${environment.paymentApiUrl}/services/order?type=${type}`, this.httpOptions).subscribe(
      (data: any) => {
        if (type === ORDER_TYPE.GIFT_STORE) {
          this.giftStores.next(data.data)
        }
        if (type === ORDER_TYPE.RESTAURANT) {
          this.restaurants.next(data.data)
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }

  /**
   * @description Create tags
   * */
  addTags(type, body, reset) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.addGiftStoreTags(body, reset)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.addRestaurantTags(body, reset)
    }
  }

  selectItem(id) {
    if (this.type === ORDER_TYPE.GIFT_STORE) {
      this.item.next(this.giftStores.value.find(el => el.id === id));
    }
    if (this.type === ORDER_TYPE.RESTAURANT) {
      this.item.next(this.restaurants.value.find(el => el.id === id));
    }
  }


  private addGiftStoreTags(body, reset) {
    this.httpClient.post(`${environment.paymentApiUrl}/gift-store/tag`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
        reset();
      },
      (error) => {
        error.error.errors.map(item => {
          this.snotifyService.error(this.translate.instant(`message.${item.message}`), '', {
            position: 'rightTop'
          })
        })
      }
    )
  }

  private addRestaurantTags(body, reset) {
    this.httpClient.post(`${environment.paymentApiUrl}/restaurant/tag`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
        reset();
      },
      (error) => {
        error.error.errors.map(item => {
          this.snotifyService.error(this.translate.instant(`message.${item.message}`), '', {
            position: 'rightTop'
          })
        })
      }
    )
  }

  getAllTags(type: ORDER_TYPE) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.getGiftStoreTags();
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.getRestaurantTags();
    }
  }

  private getGiftStoreTags() {
    this.httpClient.get(`${environment.paymentApiUrl}/gift-store/tag`, this.httpOptions).subscribe(
      (data: any) => {
        this.giftTags.next(data.data);
      },
      (error) => {
        console.log(error)
      }
    )
  }

  private getRestaurantTags() {
    this.httpClient.get(`${environment.paymentApiUrl}/restaurant/tag`, this.httpOptions).subscribe(
      (data: any) => {
        this.restaurantTags.next(data.data);
      },
      (error) => {
        console.log(error)
      }
    )
  }

  putTagsInfo(body, id, type: ORDER_TYPE) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.updateGiftTags(body, id)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.updateRestaurantTags(body, id)
    }
  }

  private updateGiftTags(body, id) {
    this.httpClient.put(`${environment.paymentApiUrl}/gift-store/tag/${id}`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        console.log(error)
      }
    )
  }

  private updateRestaurantTags(body, id) {
    this.httpClient.put(`${environment.paymentApiUrl}/restaurant/tag/${id}`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        console.log(error)
      }
    )
  }

  deleteTags(id, type: ORDER_TYPE) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.deleteGiftTags(id)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.deleteRestaurantTags(id)
    }
  }

  private deleteGiftTags(id) {
    this.httpClient.delete(`${environment.paymentApiUrl}/gift-store/tag/${id}`, this.httpOptions).subscribe(
      (data: any) => {
        let tags = this.giftTags.value.filter(item => item.id !== id);
        this.giftTags.next(tags);
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        this.snotifyService.error(this.translate.instant(`message.thisTags`), '', {
          position: 'rightTop'
        });
      }
    )
  }

  private deleteRestaurantTags(id) {
    this.httpClient.delete(`${environment.paymentApiUrl}/restaurant/tag/${id}`, this.httpOptions).subscribe(
      (data: any) => {
        let tags = this.restaurantTags.value.filter(item => item.id !== id);
        this.restaurantTags.next(tags);
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        this.snotifyService.error(this.translate.instant(`message.thisTags`), '', {
          position: 'rightTop'
        });
      }
    )
  }

  createImg(file: File, type: ORDER_TYPE, id: string) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.createGiftStoreImg(file, id)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.createRestaurantImg(file, id)
    }
  }

  private createGiftStoreImg(file: File, id) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('giftStoreProductId', id);

    this.httpClient.post(`${environment.paymentApiUrl}/gift-store`, formData, this.httpOptions).subscribe(
      (data) => {
        console.log(data)
      },
      (error) => {
        console.log(error)
      })
  }

  private createRestaurantImg(file: File, id: string) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('restaurantMenuId', id);

    this.httpClient.post(`${environment.paymentApiUrl}/restaurant`, formData, this.httpOptions).subscribe(
      (data) => {
        console.log(data)
      },
      (error) => {
        console.log(error)
      })
  }

  deleteImg(id, type) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.deleteGiftStoreImg(id)
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.deleteRestaurantImg(id)
    }
  }

  private deleteGiftStoreImg(id) {
    this.httpClient.delete(`${environment.paymentApiUrl}/gift-store/image/${id}`, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        console.log(error)
      }
    )
  }

  private deleteRestaurantImg(id) {
    this.httpClient.delete(`${environment.paymentApiUrl}/restaurant/image/${id}`, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
      },
      (error) => {
        console.log(error)
      }
    )
  }
}
