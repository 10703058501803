import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { decode } from '../../utils';
import {ORDER_TYPE} from "../enums/order-type.enum";
import {BehaviorSubject} from "rxjs";
import {IProducts, IPutProducts} from "../interfaces/IProducts";
import {SnotifyService} from "ng-snotify";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class ProductsSetupService {
  httpOptions = {
    'method': 'GET',
    headers: new HttpHeaders({
      'Authorization': `ak ${environment.key}`,
      'jwt-token': decode('token')
    })
  };

  giftProducts: BehaviorSubject<IProducts[]> = new BehaviorSubject<IProducts[]>(null);
  restaurantsProducts: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  productsItem: BehaviorSubject<IProducts> = new BehaviorSubject<IProducts>(null);

  constructor(private readonly httpClient: HttpClient,
              private readonly snotifyService: SnotifyService,
              private readonly translate: TranslateService,) {
  }

  getGiftProductsSetup(): IProducts[] {
    return this.giftProducts.value;
  }

  getRestaurantProductsSetup(): IProducts[] {
    return this.restaurantsProducts.value;
  }

  /**
   * @description Get all services orders
   * */
  getAllProductsSetup(type) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this._getGiftProducts();
    } else {
      this._getRestaurantProducts();
    }
  }

  private _getGiftProducts() {
    this.httpClient.get(`${environment.paymentApiUrl}/gift-store`, this.httpOptions).subscribe(
      (data: any) => {
        console.log(data)
        this.giftProducts.next(data.data);
      },
      (error) => {
        console.log(error)
      }
    )
  }

  private _getRestaurantProducts() {
    this.httpClient.get(`${environment.paymentApiUrl}/restaurant`, this.httpOptions).subscribe(
      (data: any) => {
        this.restaurantsProducts.next(data.data);
      },
      (error) => {
        console.log(error)
      }
    )
  }

  selectProductsItem(id, type: ORDER_TYPE) {
    if (type === ORDER_TYPE.GIFT_STORE) {
      this.productsItem.next(this.giftProducts.value.find(item => item.id === id));
    }
    if (type === ORDER_TYPE.RESTAURANT) {
      this.productsItem.next(this.restaurantsProducts.value.find(item => item.id === id));
    }
  }

  putInfo(id: string, body: IPutProducts, type, closeModal) {
    if(type === ORDER_TYPE.GIFT_STORE) {
      this.putGiftInfo(id, body, closeModal)
    }
    if(type === ORDER_TYPE.RESTAURANT) {
      this.putRestaurantInfo(id, body, closeModal)
    }
  }

  private putGiftInfo(id, body, closeModal) {
    this.httpClient.put(`${environment.paymentApiUrl}/gift-store/${id}`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
        this.getAllProductsSetup(ORDER_TYPE.GIFT_STORE);
        closeModal();
      },
      (error) => {
        console.log(error)
      }
    )
  }

  private putRestaurantInfo(id: string, body: IPutProducts, closeModal) {
    this.httpClient.put(`${environment.paymentApiUrl}/restaurant/${id}`, body, this.httpOptions).subscribe(
      (data: any) => {
        this.snotifyService.success(this.translate.instant(`message.${data.message}`), '', {
          position: 'rightTop'
        });
        this.getAllProductsSetup(ORDER_TYPE.RESTAURANT);
        closeModal();
      },
      (error) => {
        this.snotifyService.error(this.translate.instant(`message.${error.error.message}`), '', {
          position: 'rightTop'
        });
      }
    )
  }
}
