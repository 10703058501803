import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'app-latest-client-comments',
  templateUrl: './latestClientComments.component.html',
  styleUrls: ['./latestClientComments.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class LatestClientCommentsComponent implements OnInit {
  @Input() comments: any[];
  lang: string;
  constructor() {
    this.lang = localStorage.getItem('lang');
  }
  ngOnInit() {
  }
}
