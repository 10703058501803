export const routes = {
  ADMIN: {
    DASHBOARD: '/admin/dashboard',
    USER_MANAGEMENT: '/admin/user-management',
    GALLERY_MANAGEMENT: '/admin/gallery-management',
    GIFT_STORE_MANAGEMENT: '/admin/gift-store-management',
    RESTAURANT_MANAGEMENT: '/admin/restaurant-management',
    GALLERY_MANAGEMENT_ADD: '/admin/gallery-management/add',
    GALLERY_MANAGEMENT_ALL: '/admin/gallery-management/all',
    USER_MANAGEMENT_ADD: '/admin/user-management/add',
    USER_MANAGEMENT_ALL: '/admin/user-management/all',
    CHANGE_PASSWORD: '/admin/user-management/change-password',
    EDIT_INFORMATION: '/admin/user-management/edit-information',
    GIFT_ORDERS: '/admin/gift-store-management/orders',
    GIFT_PRODUCTS_SETUP: '/admin/gift-store-management/products-setup',
    RESTAURANT_ORDERS: '/admin/restaurant-management/orders',
    RESTAURANT_PRODUCTS_SETUP: '/admin/restaurant-management/products-setup',
    RESTAURANT_TAGS_SETUP: "/admin/restaurant-management/tags-setup",
    GIFT_TAGS_SETUP: "/admin/gift-store-management/tags-setup",
    GIFT_TAGS_LIST: "/admin/gift-store-management/tags-list",
    RESTAURANT_TAGS_LIST: "/admin/restaurant-management/tags-list",

  }
}
