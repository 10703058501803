import {Component, Input, OnInit} from "@angular/core";
import {NgxDropzoneChangeEvent} from "ngx-dropzone";
import {TranslateService} from "@ngx-translate/core";
import {SnotifyService} from "ng-snotify";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ORDER_TYPE} from "../../core/enums/order-type.enum";
import {GiftStoreService} from "../../core/services/gift-store.service";
import {ITags} from "../../core/interfaces/ITags";
import {IProducts, IPutProducts} from "../../core/interfaces/IProducts";
import {ProductsSetupService} from "../../core/services/products-setup.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-products-modal',
  templateUrl: './products-modal.component.html',
  styleUrls: ['./products-modal.component.scss']
})
export class ProductsModalComponent implements OnInit {
  @Input() public type;

  files: File[] = [];
  tagItems: ITags[] = [];
  selectedTagItems = [];
  lang: string;
  item: IProducts;

  form: FormGroup;
  ORDER_TYPE = ORDER_TYPE;

  constructor(private translate: TranslateService,
              private snotifyService: SnotifyService,
              private modalService: NgbModal,
              private giftService: GiftStoreService,
              private productService: ProductsSetupService,
              private fb: FormBuilder) {
    this.lang = localStorage.getItem('lang')
  }

  ngOnInit() {
    this.form = this.fb.group({
      serviceMarketingNameEn: ['', [Validators.required, Validators.maxLength(80)]],
      serviceMarketingNameAr: ['', [Validators.required, Validators.maxLength(80)]],
      descriptionAr: ['', [Validators.maxLength(300)]],
      descriptionEn: ['', [Validators.maxLength(300)]],
      optionsNoteEn: ['', [Validators.maxLength(300)]],
      optionsNoteAr: ['', [Validators.maxLength(300)]],
      onlineActive: [false],
      featureProduct: [false],
      isActive: [false],
      orderNumber: [null],
      tags: [''],
      calories: ['']
    })

    if (this.type === ORDER_TYPE.GIFT_STORE) {
      this.giftService.giftTags.subscribe(val => {
        if (val) {
          this.tagItems = val;
        }
      })
    }
    if (this.type === ORDER_TYPE.RESTAURANT) {
      this.giftService.restaurantTags.subscribe(val => {
        if (val) {
          this.tagItems = val;
        }
      })
    }

    this.productService.productsItem.subscribe(val => {
      if (val) {
        this.item = val;

        this.form.patchValue({
          onlineActive: val.onlineActive,
          orderNumber: val.order,
          featureProduct: val.isFeatured,
          optionsNoteAr: val.optionsNoteAr,
          optionsNoteEn: val.optionsNoteEn,
          descriptionEn: val.descriptionEn,
          descriptionAr: val.descriptionAr,
          serviceMarketingNameAr: val.showServiceNameAr,
          serviceMarketingNameEn: val.showServiceNameEn,
          tags: val.tags,
          isActive: val.isActive,
          calories: val.calories
        });
      }
    });
  }

  get serviceMarketingNameEn() { return this.form.get('serviceMarketingNameEn') }
  get serviceMarketingNameAr() { return this.form.get('serviceMarketingNameAr') }
  get descriptionAr() { return this.form.get('descriptionAr') }
  get descriptionEn() { return this.form.get('descriptionEn') }
  get optionsNoteAr() { return this.form.get('optionsNoteAr') }
  get optionsNoteEn() { return this.form.get('optionsNoteEn') }

  onSelect(event: NgxDropzoneChangeEvent) {
    if (this.validateFile(event.addedFiles[0]) && event.addedFiles[0].size < 500000) {
      this.files.push(...event.addedFiles);
      if (this.files.length > 1) {
        this.replaceFile();
      }
      this.giftService.createImg(event.addedFiles[0], this.type, this.item.id);
    } else {
      this.snotifyService.error(this.translate.instant(`message.fileSize`), '', {
        position: 'rightTop'
      })
    }
  }

  validateFile(file: any) {
    const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
    switch (ext.toLowerCase()) {
      case 'png': {
        return true;
      }
      case 'jpeg': {
        return true;
      }
      case 'jpg': {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  private replaceFile() {
    this.files.splice(0, 1);
  }

  onRemove(f: File) {
    this.files.splice(this.files.indexOf(f), 1);
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  clearForm() {
    this.form.patchValue({
      onlineActive: false,
      isActive: false,
      orderNumber: '',
      featureProduct: false,
      optionsNoteAr: '',
      optionsNoteEn: '',
      descriptionEn: '',
      descriptionAr: '',
      serviceMarketingNameAr: '',
      serviceMarketingNameEn: '',
      calories: ''
    });
  }

  onSubmit() {
    if (this.form.invalid ) {
      return;
    }
    let body: IPutProducts = {
      showServiceNameEn: this.form.get('serviceMarketingNameEn').value,
      showServiceNameAr: this.form.get('serviceMarketingNameAr').value,
      descriptionEn: this.form.get('descriptionEn').value,
      descriptionAr: this.form.get('descriptionAr').value,
      optionsNoteEn: this.form.get('optionsNoteEn').value,
      optionsNoteAr: this.form.get('optionsNoteAr').value,
      isFeatured: this.form.get('featureProduct').value,
      onlineActive: this.form.get('onlineActive').value,
      isActive: this.form.get('isActive').value,
      tags: this.form.get('tags').value.map(item => item.id),
      order: +this.form.get('orderNumber').value
    }

    this.type === ORDER_TYPE.RESTAURANT && (body.calories = +this.form.get('calories').value);

    this.productService.putInfo(this.item.id, body, this.type, this.closeModal);
  }

  deleteImg(id) {
    this.giftService.deleteImg(id, this.type)
  }
}
