import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  httpOptions = {
    method: 'GET',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
      'access-control-allow-origin': '*',
    }),
  };
  httpOptionsPost = {
    method: 'POST',
    headers: new HttpHeaders({
      authorization: 'ak ' + environment.key,
      'access-control-allow-origin': '*',
    }),
  };
  constructor(private _http: HttpClient) {}

  public getInvoiceDetailsById(invoiceId) {
    return this._http.get(
      `${environment.paymentApiUrl}/payments/invoices/${invoiceId}`,
      this.httpOptions
    );
  }
  public getInvoiceIdByTransCount(transCount) {
    return this._http.get<{ id: string }>(
      `${environment.paymentApiUrl}/payments/invoices/id/${transCount}`,
      this.httpOptions
    );
  }
  public getTelrPaymentPage(invoiceData: ICustomerInvoice) {
    return this._http.post<ITelrCreateOrderResponse>(
      `${environment.paymentApiUrl}/telr/orders`,
      {
        ...invoiceData,
        request_source: 2
      },
      this.httpOptionsPost
    );
  }
  public getHyperPayPaymentPage(invoiceData: ICustomerInvoice) {
    return this._http.post<{ checkoutId: string }>(
      `${environment.paymentApiUrl}/hyperpay/orders`,
      invoiceData,
      this.httpOptionsPost
    );
  }
  public getHyperpayPaymentStatus(checkoutId: string) {
    return this._http.get<{
      result: { code: string; description: string };
      transCount: number;
    }>(
      `${environment.paymentApiUrl}/hyperpay/orders/${checkoutId}`,

      this.httpOptions
    );
  }
}
export interface ICustomerInvoice {
  customerIdNumber: string;
  customerMobileNumber: string;
  customerProfileNumber: string;
  transCount: number;
}
export interface ITelrCreateOrderResponse {
  method: string;
  order?: {
    ref: string;
    url: string;
  };
  error?: {
    message: string;
    note: string;
  };
}
export interface ITelrOrderStatus {
  transCount: number;
  status: {
    code: number;
    text: string;
  };
}
