import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  public currentLang = 'ar';
  toggleClass = 'ft-maximize';
  public isCollapsed = true;
  constructor(public translate: TranslateService, private router: Router) {}
  lang = localStorage.getItem('lang');
  ngOnInit() {
    // const browserLang: string = translate.getBrowserLang();

    if (this.lang) {
      this.translate.use(this.lang);
      this.changeDirection(this.lang);
    } else {
      this.translate.use(this.currentLang);
      localStorage.setItem('lang', this.currentLang);
    }
    // translate.use(browserLang.match(/en/) ? browserLang : 'ar')
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('lang', language);
    this.changeDirection(language);
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url], { skipLocationChange: true });
    window.location.reload();
  }

  changeDirection(lang) {
    if (lang === 'en') {
      $('.navbar-toggle').removeClass('float-left');
      $('.navbar-toggle').addClass('float-right');

      $('.customizer').addClass('customizer-l');
      $('.customizer-l').removeClass('customizer');

      $('.app-sidebar').addClass('app-sidebar-l');
      $('.app-sidebar-l').removeClass('app-sidebar');

      $('.off-canvas-sidebar').addClass('off-canvas-sidebar-l');
      $('.off-canvas-sidebar-l').removeClass('off-canvas-sidebar');

      $('.wrapper').addClass('wrapper-l');
      $('.wrapper-l').removeClass('wrapper');

      $('.spHover').addClass('ltr');
      $('.spHover').removeClass('rtl');
      $('.mb').addClass('text-left');
      $('.mb').removeClass('text-right');

      $('.main-panel').addClass('main-panel-l');
      $('.main-panel-l').removeClass('main-panel');

      $('.bootstrap-navbar').addClass('bootstrap-navbar-l');
      $('.bootstrap-navbar-l').removeClass('bootstrap-navbar');

      $('.navbar-header').addClass('navbar-header-l');
      $('.navbar-header-l').removeClass('navbar-header');

      $('.navbar .container-fluid').addClass('container-fluid-l');

      // $('.navbar-container').addClass('navbar-container-l');

      $('.navbar-header-l > a.ml-2.display-inline-block').removeClass('ml-2');
      $('.navbar-header-l > a.display-inline-block').addClass('mr-2');

      $('.langimg').addClass('langimg-l');
      $('.langimg-l').removeClass('langimg');

      $('.copyright').addClass('text-center');
      $('.dropdown-menu').addClass('text-left');
      $('.media-body i').addClass('smallf');
      $('aw-wizard-navigation-bar').attr('dir', 'ltr');
    } else if (lang === 'ar') {
      $('.navbar-toggle').removeClass('float-right');
      $('.navbar-toggle').addClass('float-left');

      $('.customizer-l').addClass('customizer');
      $('.customizer').removeClass('customizer-l');

      $('.app-sidebar-l').addClass('app-sidebar');
      $('.app-sidebar').removeClass('app-sidebar-l');

      $('.off-canvas-sidebar-l').addClass('off-canvas-sidebar');
      $('.off-canvas-sidebar').removeClass('off-canvas-sidebar-l');

      $('.wrapper-l').addClass('wrapper');
      $('.wrapper').removeClass('wrapper-l');

      $('.main-panel-l').addClass('main-panel');
      $('.main-panel').removeClass('main-panel-l');

      $('.bootstrap-navbar-l').addClass('bootstrap-navbar');
      $('.bootstrap-navbar').removeClass('bootstrap-navbar-l');

      $('.navbar .container-fluid').removeClass('container-fluid-l');
      $('.navbar .navbar-container').removeClass('navbar-container-l');

      $('.navbar-header-l').addClass('navbar-header');
      $('.navbar-header').removeClass('navbar-header-l');

      $('.navbar-header-l > a.mr-2.display-inline-block').removeClass('mr-2');
      $('.navbar-header-l > a.display-inline-block').addClass('ml-2');

      $('.langimg-l').addClass('langimg');
      $('.langimg').removeClass('langimg-l');

      $('.dropdown-menu').removeClass('text-left');
      $('.copyright').removeClass('text-center');
      $('.media-body i').removeClass('smallf');

      $('.spHover').removeClass('ltr');
      $('.spHover').addClass('rtl');
      $('.mb').removeClass('text-left');
      $('.mb').addClass('text-right');
    }
  }
  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }
}
