import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { LocalService } from 'app/local.service';

@Injectable()
export class AuthService {
  token: string;

  constructor(private localService: LocalService) {}

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    // your code for checking credentials and getting tokens for for signing in user
  }

  logout() {
    this.token = null;
  }

  getToken() {
    return this.token;
  }

  isAuthenticated() {
    if (
      this.localService.getJsonValue('srd') ||
      this.localService.getJsonValue('srp')
    ) {
      return true;
    }
    return false;
  }
}
