import {Component, OnInit, ViewChild} from "@angular/core";
import {DatatableComponent, ColumnMode} from "@swimlane/ngx-datatable";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GiftStoreService} from "../../core/services/gift-store.service";
import {IGiftStore} from "../../core/interfaces/IGiftStore";

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  @ViewChild(DatatableComponent) detailTable: DatatableComponent;
  currentRate = 0;
  ColumnMode = ColumnMode;
  item: IGiftStore;
  lang: string;

  constructor(private modalService: NgbModal,
              private giftStoreService: GiftStoreService) {
    this.lang = localStorage.getItem('lang');
  }

  ngOnInit() {
    this.giftStoreService.item.subscribe(value => {
      if (value) {
        this.item = value;
      }
    })
  }

  closeModal() {
    this.modalService.dismissAll();
  }
}
