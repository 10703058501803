import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: 'app-customer-satisfaction',
  templateUrl: './customer-satisfaction.component.html',
  styleUrls: ['./customer-satisfaction.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class CustomerSatisfactionComponent implements OnInit {
  @Input() hotAngryRatePercent: any;
  @Input() hotAngryRate: any;
  @Input() angryRatePercent: any;
  @Input() angryRate: any;
  @Input() negativeRatePercent: any;
  @Input() negativeRate: any;
  @Input() neutralRatePercent: any;
  @Input() neutralRate: any;
  @Input() positiveRatePercent: any;
  @Input() positiveRate: any;
  constructor() {
  }
  ngOnInit() {
  }
}
