import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {DatatableComponent, ColumnMode} from "@swimlane/ngx-datatable";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {OrderDetailsComponent} from "../order-details/order-datails.component";
import {GiftStoreService} from "../../core/services/gift-store.service";

@Component({
  selector: 'app-orders-table',
  templateUrl: './orders-table.component.html',
  styleUrls: ['./orders-table.component.scss'],
})
export class OrdersTableComponent implements OnInit {
  @Input() items: any;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  ColumnMode = ColumnMode;

  constructor(private modalService: NgbModal,
              private giftStoreService: GiftStoreService) {
  }
  ngOnInit() {
  }

  openOrderDetailModal(event, id, firstChild: any) {
    event.stopPropagation();

    firstChild && firstChild.parentElement && firstChild.parentElement.parentElement &&
    firstChild.parentElement.parentElement.blur();
    this.modalService.open(OrderDetailsComponent, {centered: true, size: 'lg'});

    this.giftStoreService.selectItem(id)
  }
}
