import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { UiSwitchModule } from 'ngx-ui-switch';

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import {OrdersTableComponent} from "./orders-table/orders-table.component";
import {OrderDetailsComponent} from "./order-details/order-datails.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {ProductsTableComponent} from "./products-table/products-table.component";
import {AddTagsComponent} from "./add-tags/add-tags.component";
import {ReactiveFormsModule} from "@angular/forms";
import {TagsListTableComponent} from "./tags-list-table/tags-list-table.component";
import {ProductsModalComponent} from "./products-modal/products-modal.component";
import {TagInputModule} from "ngx-chips";
import {NgxDropzoneModule} from "ngx-dropzone";
import {DashboardCardComponent} from "./dashboard-card/dashboard-card.component";
import {ClosedRequestsComponent} from "./closed-requests/closed-requests.component";
import {InPatientComponent} from "./inPatient/inPatient.component";
import {OpenRequestsComponent} from "./open-requests/open-requests.component";
import {TopRatedComponent} from "./top-rated/top-rated.component";
import {LatestClientCommentsComponent} from "./latestClientComments/latestClientComments.component";
import {ServicesOrdersComponent} from "./services-orders/services-orders.component";
import {CustomerSatisfactionComponent} from "./customer-satisfaction/customer-satisfaction.component";
import {OrderReviewComponent} from "./order-review/order-review.component";

@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    NgbModule,
    TranslateModule,
    UiSwitchModule,
    OrdersTableComponent,
    ProductsTableComponent,
    AddTagsComponent,
    TagsListTableComponent,
    DashboardCardComponent,
    ClosedRequestsComponent,
    ClosedRequestsComponent,
    InPatientComponent,
    OpenRequestsComponent,
    TopRatedComponent,
    LatestClientCommentsComponent,
    ServicesOrdersComponent,
    CustomerSatisfactionComponent,
    OrderReviewComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    UiSwitchModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    TagInputModule,
    NgxDropzoneModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    CustomizerComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    OrdersTableComponent,
    OrderDetailsComponent,
    ProductsTableComponent,
    AddTagsComponent,
    TagsListTableComponent,
    ProductsModalComponent,
    DashboardCardComponent,
    ClosedRequestsComponent,
    InPatientComponent,
    OpenRequestsComponent,
    TopRatedComponent,
    LatestClientCommentsComponent,
    ServicesOrdersComponent,
    CustomerSatisfactionComponent,
    OrderReviewComponent
  ],
  entryComponents: [
    OrderDetailsComponent,
    ProductsModalComponent
  ]
})
export class SharedModule {

}
