import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {ColumnMode, DatatableComponent} from "@swimlane/ngx-datatable";
import {ITags} from "../../core/interfaces/ITags";
import {GiftStoreService} from "../../core/services/gift-store.service";
import {ORDER_TYPE} from "../../core/enums/order-type.enum";
import Swal from "sweetalert2";

@Component({
  selector: "app-tags-list-table",
  templateUrl: './tags-list-table.component.html',
  styleUrls: ['./tags-list-table.component.scss']
})
export class TagsListTableComponent implements OnInit {

  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;

  @Input() items: ITags[];
  @Input() type: ORDER_TYPE;
  editing = {};
  private lang: string;

  constructor(private tagsService: GiftStoreService) {
    this.lang = localStorage.getItem('lang')
  }

  ngOnInit(): void {
  }

  updateValue(event, cell, rowIndex, id) {
    let body;
    if (typeof event == "boolean") {
      body = {
        [cell]: event
      }
    } else {
      this.editing[rowIndex + '-' + cell] = false;
      this.items[rowIndex][cell] = event.target.value;
      this.items = [...this.items];

      body = {
        [cell]: event.target.value
      }
    }
    this.tagsService.putTagsInfo(body, id, this.type);
  }

  deleteTag(id) {
    Swal({
      title: this.lang == 'en' ? 'Are you sure?' : 'تأكيد العملية',
      text:
        this.lang == 'en'
          ? 'You won\'t be able to revert this!'
          : '!لن يكون بإستطاعتك التراجع',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText:
        this.lang == 'en' ? 'Yes, delete it!' : 'نعم ، احذفها',
      cancelButtonText: this.lang == 'en' ? 'No' : 'لا',
    }).then((result) => {
      if (result.value) {
        this.tagsService.deleteTags(id, this.type);
      }
    });
  }
}
