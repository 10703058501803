import {Component, Input, OnInit} from "@angular/core";
import {IinvoicesTotalAmount} from "../../core/interfaces/IDashboard";

@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss']
})
export class DashboardCardComponent implements OnInit {
  @Input() user: number;
  @Input() appointmentCount: number;
  @Input() invoicesTotalAmount: IinvoicesTotalAmount;
  @Input() overallSatisfaction: any;
  constructor() {
  }

  ngOnInit() {
  }
}
