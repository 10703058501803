import {Component, Input, OnInit} from "@angular/core";
import {ORDER_TYPE} from "../../core/enums/order-type.enum";

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss', '../../admin/dashboard/dashboard.component.scss']
})
export class OrderReviewComponent implements OnInit {
  @Input() latestOrdersReview: any[];
  ORDER_TYPE = ORDER_TYPE;

  constructor() {
  }
  ngOnInit() {
  }
}
