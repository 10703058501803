import {Component, Input, OnInit, ViewChild} from "@angular/core";
import {DatatableComponent, ColumnMode} from "@swimlane/ngx-datatable";
import {ServiceTypeEnum} from "../../core/enums/service-type.enum";

@Component({
  selector: 'app-open-requests',
  templateUrl: './open-requests.component.html',
  styleUrls: ['./open-requests.component.scss','../../admin/dashboard/dashboard.component.scss']
})
export class OpenRequestsComponent implements OnInit {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  ColumnMode = ColumnMode;
  @Input() rows: any[];

  ServiceType = ServiceTypeEnum;

  constructor() {
  }

  ngOnInit() {
  }

}
