import { Component, OnInit } from '@angular/core';
import {SpinnerService} from '../shared/spinner/spinner.service';

@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent implements OnInit {
  loading;

  constructor(private spinner: SpinnerService) { }

  ngOnInit() {
    this.spinner.spinner.subscribe(val => {
      this.loading = val;
    })
  }

}
