import {Component, Input, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ORDER_TYPE} from "../../core/enums/order-type.enum";
import {GiftStoreService} from "../../core/services/gift-store.service";
import {ICreateTags} from "../../core/interfaces/ITags";

@Component({
  selector: 'app-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.scss']
})
export class AddTagsComponent implements OnInit {
  form: FormGroup;
  lang: string;
  @Input() type: number;

  constructor(private fb: FormBuilder,
              private tagsService: GiftStoreService) {
    this.lang = localStorage.getItem('lang')
  }

  ngOnInit() {
    this.form = this.fb.group({
      nameAr: ['', [Validators.required, Validators.maxLength(25)]],
      nameEn: ['', [Validators.required, Validators.maxLength(25)]],
      onlineActive: [false],
      orderNumber: ['']
    })
  }

  get nameAr() { return this.form.get('nameAr') }
  get nameEn() { return this.form.get('nameEn') }
  get orderNumber() { return this.form.get('orderNumber') }
  get isActive() { return this.form.get('onlineActive') }

  clearForm = () => {
    this.form.reset();
    this.form.patchValue({
      nameAr: '',
      nameEn: '',
      onlineActive: false,
      orderNumber: ''
    });
  }

  onSubmit(type) {
    if (this.form.invalid) {
      return;
    }

    const body: ICreateTags = {
      order: +this.orderNumber.value,
      nameEn: this.nameEn.value,
      nameAr: this.nameAr.value,
      isActive: this.isActive.value,
    };

    this.tagsService.addTags(type, body, this.clearForm);
  }
  keyPressAlphanumeric(event) {

    var input = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z-ا-ي-ؤ-ئ-ء]/.test(input)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
